import React from 'react';
import Title from '../../components/Title';
import CardFlipped from '../../components/CardFlipped';
import Card from '../../components/Card';
import Layout from '../../components/Layout';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import '../../assets/css/index.css';

const Sold = ({
  data: {
    allContentfulProperties: { nodes: properties },
  },
}) => {
  const sold = properties.filter((property) => {
    const tag = property.tags[0].content;
    return tag === 'sold' ? property : null;
  });

  return (
    <Layout>
      <Container className="section">
        <div>
          <Title title="recently sold" />
          <div className="section-center">
            {sold.map((item, index) => {
              return index % 2 === 0 ? (
                <Card item={item} key={index} />
              ) : (
                <CardFlipped item={item} key={index} />
              );
            })}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  .section-center {
    flex-direction: column;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  svg {
    font-size: 2rem;
    padding-top: 0.25rem;
    transition: 0.5s;
  }
  .more-btn {
    font-size: 2rem;
    color: #2c3e50;
    display: flex;
    align-items: center;
    &:hover > svg {
      transition: 0.5s;
      transform: translateX(1rem);
    }
  }
`;

export const data = graphql`
  {
    allContentfulProperties {
      nodes {
        id
        featured
        title
        bedrooms
        bathrooms
        description {
          description
        }
        listPrice
        images {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          title
        }
        tags {
          content
        }
      }
    }
  }
`;

export default Sold;
